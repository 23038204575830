import React, { useState } from 'react'
import { useAccordionButton } from 'react-bootstrap';

interface CollapseToggleProps {
    eventKey: String,   
}

const CollapseToggle = (props: CollapseToggleProps) => {
    const [collapsed, setCollapsed] = useState(true)

    const decoratedOnClick = useAccordionButton(props.eventKey.toString(), () => {
        setCollapsed(!collapsed)
    })

    return(
        <button onClick={decoratedOnClick} className="btn-collapse">
            {collapsed ?
            <svg className="bi bi-plus me-3 mt-2" width="2rem" height="2rem" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" data-toggle="collapse">
                <path fillRule="evenodd" d="M8 3.5a.5.5 0 01.5.5v4a.5.5 0 01-.5.5H4a.5.5 0 010-1h3.5V4a.5.5 0 01.5-.5z" clipRule="evenodd"/>
                <path fillRule="evenodd" d="M7.5 8a.5.5 0 01.5-.5h4a.5.5 0 010 1H8.5V12a.5.5 0 01-1 0V8z" clipRule="evenodd"/>
            </svg>
            :
            <svg className="bi bi-x me-3 mt-2" width="2rem" height="2rem" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" data-toggle="collapse">
                <path fillRule="evenodd" d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z" clipRule="evenodd"/>
                <path fillRule="evenodd" d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z" clipRule="evenodd"/>
            </svg>}
        </button>
    )
}

export default CollapseToggle