import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import { Container, Row, Col, Accordion, Card } from 'react-bootstrap'
import Layout from '../layouts/layout'
import HeaderAndText from '../components/header-and-text'
import ContentCard from '../components/card'
import LearnMore from '../components/learn-more'
import CollapseToggle from '../components/collapse-toggle'
import { Helmet } from 'react-helmet'

interface AnswerContentful {
    answer: String
}

interface FAQContentful {
    question: String,
    answer: AnswerContentful
}

interface DescriptionContentful {
    description: String
}
  
interface MediaContentful {
    url: String
}

interface CardContentful {
    title: String,
    description: DescriptionContentful,
    media: MediaContentful
}  

const FeaturesPage = () => {
    const data = useStaticQuery(graphql`
          query FeaturesQuery {
              contentfulLayout(contentful_id: {eq: "1kgcnT6M0Nlr24Zz3D2tU9"}) {
                contentModule {
                    ... on ContentfulContentObject {
                        title
                        description {
                          description
                        }
                        link_text
                        child_content_objects {
                          title
                          description {
                            description
                          }
                          media {
                            url
                          }
                        }
                    }
                    ... on ContentfulTestimonialGallery {
                        gallery_items {
                        quote {
                            quote
                        }
                        name
                        company
                        }
                    }
                    ... on ContentfulFaq {
                        question
                        answer {
                            answer
                        }
                    }
                }
                hero {
                    header_title
                    hero_body {
                        hero_body
                    }
                    heroImage {
                        url
                    }
                }
              }
          }
      `)

    const page = data.contentfulLayout

    const heroImage = page.hero.heroImage.url
    const heroTitle = page.hero.header_title
    const heroBody  = page.hero.hero_body.hero_body

    const section1Header = page.contentModule[0].title
    const section1Desc   = page.contentModule[0].description.description
    const section1Cards: CardContentful[] = page.contentModule[0].child_content_objects

    const section2Header = page.contentModule[1].title
    const section2Desc   = page.contentModule[1].description.description
    const section2Cards: CardContentful[] = page.contentModule[1].child_content_objects

    const section3Header = page.contentModule[2].title
    const section3Desc   = page.contentModule[2].description.description
    const section3Cards: CardContentful[] = page.contentModule[2].child_content_objects

    const learnMoreHeader   = page.contentModule[3].title
    const learnMoreLinkText = page.contentModule[3].link_text

    const faqHeader = page.contentModule[5].title
    const faqItems: FAQContentful[]  = page.contentModule.slice(6)

    return(
        <Layout>
            <Helmet>
                <title>O3 Edge Features | Prospecting Solution for Retirement Plan Advisors</title>
                <meta name="description" content="Get detailed plan information, meeting ready materials, and personal plan experts at your disposal. Learn more about O3 Edge features." />
            </Helmet>
            <div className="hero">
                <div className="background-container background-container-image" style={{ backgroundImage: `url(${heroImage})` }} />
                <Container>
                    <Row>
                        <Col lg="4" className="hero-content hero-img-content text-center text-lg-start">
                            <h1 className="text-primary">{heroTitle}</h1>
                            <h2 className="fw-normal">{heroBody}</h2>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="section-container">
                <HeaderAndText 
                    cssClass={"bg-white"}
                    header={section1Header}
                    text={section1Desc} 
                    />
                
                <div className="pt-3">
                    <Container>
                        <Row className="px-0 d-flex justify-content-center">
                            {section1Cards.map(card => 
                                <Col key={card.title.toString()} size="12" md="5" className="px-5 offset-md-1 text-center">
                                    <ContentCard header={card.title} image={card.media.url} text={card.description.description} />
                                </Col>
                                )}
                        </Row>
                    </Container>
                </div>
            </div>

            <div className="section-container bg-secondary">
                <HeaderAndText 
                    cssClass={"bg-secondary"}
                    header={section2Header}
                    text={section2Desc} 
                    />
                
                <div className="pt-3">
                    <Container>
                        <Row className="px-0 d-flex justify-content-center">
                            {section2Cards.map(card => 
                                <Col key={card.title.toString()} size="12" md="5" className="px-5 offset-md-1 text-center">
                                    <ContentCard header={card.title} image={card.media.url} text={card.description.description} />
                                </Col>
                                )}
                        </Row>
                    </Container>
                </div>
            </div>

            <div className="section-container">
                <HeaderAndText 
                    cssClass={"bg-white"}
                    header={section3Header}
                    text={section3Desc} 
                    />
                
                <div>
                    <Container>
                        <Row className="px-0 d-flex justify-content-center">
                            {section3Cards.map(card => 
                                <Col key={card.title.toString()} size="12" md="4" className="px-5 text-center">
                                    <ContentCard header={card.title} image={card.media.url} text={card.description.description} />
                                </Col>
                                )}
                        </Row>
                    </Container>
                </div>
            </div>

            <LearnMore title={learnMoreHeader} linkText={learnMoreLinkText} />

            <div className="section-container header-text">
                <Container>
                    <h3 className="text-primary text-center mb-5">{faqHeader}</h3>
                    <Accordion alwaysOpen flush>
                        {faqItems.map((item, index) => 
                            <Card key={`faq-item-${index}`} className="mb-4 container-collapse offset-md-2 bg-light p-4 d-flex">
                                <Card.Header className="collapse-header d-flex align-items-center">
                                    <CollapseToggle eventKey={`${index}`} />
                                    <h4 className="mb-0 title text-black">{item.question}</h4>
                                </Card.Header>
                                <Accordion.Collapse eventKey={`${index}`}>
                                    <Card.Body>{item.answer.answer}</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            )}
                    </Accordion>
                </Container>
            </div>
        </Layout>
    )
}

export default FeaturesPage