import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

interface HeaderAndTextProps {
    header: String,
    text?: String,
    buttonText?: String,
    buttonLink?: String,
    cssClass?: String
}

const HeaderAndText = (props: HeaderAndTextProps) => {
    return(
        <div className={props.cssClass ? `header-text ${props.cssClass}` : "header-text"}>
            <Container>
                <Row>
                    <Col className="offset-md-2 text-center" md="8">
                        <h3 className="text-primary">
                            {props.header}
                        </h3>
                        {props.text ? <p>{props.text}</p> : null}
                        {props.buttonText && props.buttonLink ? 
                            <a href={props.buttonLink.toString()} className="btn btn-secondary text-white mt-4">{props.buttonText}</a>
                            : null}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default HeaderAndText