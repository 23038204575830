import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import VideoPlaceholder from "../images/video-placeholder.jpg"

interface LearnMoreProps {
    linkText?: String,
    title: String
}

const LearnMore = (props: LearnMoreProps) => {
    const data = useStaticQuery(graphql`
        query LearnMoreQuery {
            contentfulLayout(contentful_id: {eq: "T3H8XFGXUuivr4KGfj91v"}) {
                hero {
                    heroImage {
                        url
                    }
                }
            }
        }
    `)

    const page  = data.contentfulLayout

    const heroVideoSource   = page.hero.heroImage.url

    return(
        <div className="py-5 position-relative learn-more">
            <Container>
                <Row className="py-5">
                    <Col md="8" className="offset-md-2 d-flex justify-content-between align-items-center text-white hero-content">
                        <h1>{props.title}</h1>
                        {props.linkText ? 
                            <a href="/request-a-demo" className="btn btn-secondary ms-3">{props.linkText}</a>
                            : null}
                    </Col>
                </Row>
            </Container>

            <div className="background-container">
                <video autoPlay muted loop className="video" height="100%" poster={VideoPlaceholder}>
                    <source src={heroVideoSource.toString()} type="video/mp4" />
                </video>
            </div>
        </div>
    )
}

export default LearnMore